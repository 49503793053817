//yMaps
if ($( "#map" ).length) {
    var _this = $('#map');
    ymaps.ready(base_map);

    function base_map() {
        var base2_map = _this.data('coords');
        var base2_center = _this.data('center');
        var base2_names = _this.data('object');

        if ($(window).width() <= 740) {
            base2_map = base2_map.split(',');
            base2_center = base2_center.split(',');

            var myMap = new ymaps.Map('map', {
                    center: base2_map,
                    zoom: 18,
                    controls: ['geolocationControl', 'zoomControl']
                }),

                myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
                    hintContent: base2_names,
                    balloonContent: base2_names,
                }, {
                    iconLayout: 'default#image',
                    iconImageHref: '/images/balloon.png',
                    iconImageSize: [49, 70],
                    iconImageOffset: [-15, -70]
                });

        } else {

            base2_map = base2_map.split(',');
            base2_center = base2_center.split(',');

            var myMap = new ymaps.Map('map', {
                    center: base2_center,
                    zoom: 17,
                    controls: ['geolocationControl', 'zoomControl']
                }),

                myPlacemark = new ymaps.Placemark(base2_map, {
                    hintContent: base2_names,
                    balloonContent: base2_names,
                }, {
                    iconLayout: 'default#image',
                    iconImageHref: '/images/balloon.png',
                    iconImageSize: [49, 70],
                    iconImageOffset: [-15, -70]
                });
        }

        myMap.geoObjects.add(myPlacemark);
        myMap.behaviors.disable('scrollZoom');
    }
}