if (window.innerWidth < 991.98) {
    var contentLists = document.querySelectorAll('ul.js-contentList');
    var titles = document.querySelectorAll('.js-f__title');

    contentLists.forEach(function(contentList) {
        contentList.style.display = 'none';
    });

    titles.forEach(function(title) {
        title.addEventListener('click', function() {
            var nextElement = this.nextElementSibling;

            if (nextElement.classList.contains('js-open')) {
                titles.forEach(function(title) {
                    title.classList.remove('js-checked');
                });

                nextElement.classList.remove('js-open');
                nextElement.style.display = 'none';
            } else {
                var tabId = this.getAttribute('data-id');
                var openContentList = document.getElementById(tabId);

                contentLists.forEach(function(contentList) {
                    contentList.classList.remove('js-open');
                    contentList.style.display = 'none';
                });

                titles.forEach(function(title) {
                    title.classList.remove('js-checked');
                });

                this.classList.add('js-checked');
                openContentList.style.display = 'block';
                openContentList.classList.add('js-open');
            }
        });
    });
}
