import $ from 'jquery';
window.$ = window.jQuery = $;

require('./yandex.map');
require('./bootstrap');
require('./jqueryMmenu');
require('./swiper');
require('./maskedinput');
require('./input-file');

require('./menuFooter');
require('./servicesTab');
require('./tabs');
require('./header_sticky');
require('./page_loading');
require('./footer_scrolling');
require('./dropzone');
require('./scrollToTopBtn');







// Функция для обновления страницы
function reloadPage() {
    location.reload();
}

// Запомните начальную ширину экрана
/*var initialWidth = window.innerWidth;

// Обработчик события изменения размера окна
window.addEventListener('resize', function() {
    // Проверьте, изменилась ли ширина экрана
    if (window.innerWidth !== initialWidth) {
        // Если да, обновите страницу
        reloadPage();
    }
});*/

