$(document).ready(function() {
    // При загрузке страницы скрывается содержимое всех вкладок, кроме первой активной
    $('.tab-pane').not('.active').hide();

    // При клике на вкладку
    $('.nav-link').click(function(e) {
        e.preventDefault();
        // Удаляем классы active у всех вкладок и активной ссылки
        $('.nav-link').removeClass('show active');
        $('.tab-pane').removeClass('show active').hide();

        // Добавляем класс active к выбранной вкладке и ссылке
        $(this).addClass('show active');
        $($(this).attr('href')).addClass('show active').show();
    });
});