// ============== Sticky Header Function ============


$(window).on("scroll", function() {
    var header_height = $("header").innerHeight();
    if ($(this).scrollTop() > 1){
        $('header.stick').addClass("sticky animated slideInDown");

    }
    else{
        $('header.stick').removeClass("sticky animated slideInDown");
    }
});