window.addEventListener('DOMContentLoaded', function() {
    var listItems = document.querySelectorAll('.js-listItem');
    var acardoins = document.querySelectorAll('.js-tab');
    var activeItem = null;

    // Добавляем класс "active" к первому элементу списка
    if (listItems.length > 0) {
        listItems[0].classList.add('itemActive');
        activeItem = listItems[0];
    }

    for (var i = 0; i < listItems.length; i++) {
        listItems[i].addEventListener('click', function(event) {
            event.preventDefault();
            // Скрыть все блоки acardoin
            for (var j = 0; j < acardoins.length; j++) {
                acardoins[j].style.display = 'none';
            }

            // Получить номер выбранного listItem
            var listItemIndex = Array.from(listItems).indexOf(this);

            // Показать соответствующий блок acardoin
            if (acardoins[listItemIndex]) {
                acardoins[listItemIndex].style.display = 'block';
            }

            // Удалить класс "active" с предыдущего активного элемента
            if (activeItem && activeItem !== this) {
                activeItem.classList.remove('itemActive');
            }

            // Добавить класс "active" к текущему элементу
            this.classList.add('itemActive');

            // Обновить активный элемент
            activeItem = this;
        });
    }
});

