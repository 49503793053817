import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

document.addEventListener('DOMContentLoaded', function() {
    //Main Slider IMG
    const swiperIMG = new Swiper('.js-slider-img', {
        loop: false,
        speed: 2400,
        parallax: true,
        pagination: {
            el: '.slider-pagination-count .total',
            type: 'custom',
            renderCustom: function(swiper, current, total) {
                let totalRes = total >= 10 ? total : `0${total}`;
                return totalRes
            }
        }
    });
    //Main Slider Text
    const swiperText = new Swiper('.js-slider-text', {
        loop: false,
        speed: 2400,
        /*mousewheel: {
            invert: false,
        },*/
        pagination: {
            el: '.swiperPagination',
            clickable: true
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true
        },
        navigation: {
            prevEl: '.prev-1',
            nextEl: '.next-1'
        }
    });

    swiperIMG.controller.control = swiperText;
    swiperText.controller.control = swiperIMG;

    //Partners main page
    var swiper = new Swiper(".js-mainPartners", {
        //slidesPerView: "auto",
        slidesPerView: 3,
        spaceBetween: 0,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: "auto",
                spaceBetween: 0,
                //centeredSlides: true,
                slidesPerGroup: 1,
            },
            480: {
                slidesPerView: "auto",
                spaceBetween: 15,
                //centeredSlides: true,
                slidesPerGroup: 1,
            },
            580: {
                slidesPerView: 2,
                spaceBetween: 15,
                slidesPerGroup: 2,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
                slidesPerGroup: 2,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
        },

    });

    //Project main page
    var swiper = new Swiper(".js-mainProject", {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
            nextEl: '.next-4',
            prevEl: '.prev-4',
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: "auto",
                spaceBetween: 15,
                //slidesPerGroup: 1,
            },
            375: {
                slidesPerView: "auto",
                spaceBetween: 15,
                //slidesPerGroup: 1,
            },
            480: {
                slidesPerView: "auto",
                spaceBetween: 15,
                //slidesPerGroup: 1,
            },
            580: {
                slidesPerView: "auto",
                spaceBetween: 15,
                slidesPerGroup: 2,
            },
            768: {
                slidesPerView: "auto",
                spaceBetween: 15,
                slidesPerGroup: 2,
            },
            992: {
                slidesPerView: "auto",
                spaceBetween: 15,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
        },
    });

    //Отзывы
    let swipNews = new Swiper(".js-swipReviews", {
            slidesPerView: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.next-5',
                prevEl: '.prev-5',
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    autoHeight: true,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    autoHeight: true,
                },
                580: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                    autoHeight: true,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                    autoHeight: false,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
            },
});

    //News main page
    var project = new Swiper(".js-mainNews", {
        slidesPerView: 3,
        // spaceBetween: 0,
        navigation: {
            nextEl: '.next-6',
            prevEl: '.prev-6',
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: "auto",
                // spaceBetween: 15,
                //slidesPerGroup: 1,
            },
            375: {
                slidesPerView: "auto",
                // spaceBetween: 0,
                //slidesPerGroup: 1,
            },
            480: {
                slidesPerView: "auto",
                // spaceBetween: 0,
                //slidesPerGroup: 1,
            },
            580: {
                slidesPerView: "auto",
                // spaceBetween: 0,
                slidesPerGroup: 2,
            },
            768: {
                slidesPerView: "auto",
                // spaceBetween: 0,
                slidesPerGroup: 2,
            },
            992: {
                slidesPerView: "auto",
                // spaceBetween: 0,
            },
            1200: {
                slidesPerView: 3,
                // spaceBetween: 0,
            },
        },
    });

});


//Делаем обновление страницы сайта
let currentWidth = window.innerWidth;

window.addEventListener('resize', () => {
    if (window.innerWidth !== currentWidth) {
        currentWidth = window.innerWidth;
        location.reload();
    }
});