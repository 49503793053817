import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";


document.addEventListener("DOMContentLoaded", function () {
    Dropzone.autoDiscover = false; //Отключение автообнаружения, иначе Dropzone попытается подключиться дважды
    var dropzone = new Dropzone("#kt_dropzonejs", { //".dropzone" — класс контейнера, куда загружать картинки
        url: window.dropzoneUrl,
        params: {_token: window.dropzoneToken},
        autoProcessQueue: false,
        paramName: "file",
        uploadMultiple: true, //включение загрузки нескольких файлов
        clickable: true,
        parallelUploads: 10,
        maxFilesize: 10, // Максимальный размер файла в МБ
        maxFiles: 3, //максимальное количество загружаемых файлов
        addRemoveLinks: true, //ссылка для удаления загруженного файла
        dictRemoveFile: 'Удалить', //имя ссылки для удаления загруженного файла
        //dictRemoveFileConfirmation: 'Вы уверены что хотете удалить файл?', //вопрос перед удалением загруженного файла
        acceptedFiles: '.pdf,.doc,.docx',// Допустимые типы файлов
        dictInvalidFileType: "Неверный тип файла", // Сообщение при недопустимом типе файла
        renameFile: function (file) {
            var dt = new Date();
            var time = dt.getTime();
            return time + file.name;
        },

        dictFileTooBig: "Файл слишком большой ({{filesize}}МБ). Максимальный размер: {{maxFilesize}}МБ.", // Сообщение при слишком большом файле
        init: function () {

        var submitButton = document.querySelector("#form_submit_button");
        var myDropzone = this;

        // First change the button to actually tell Dropzone to process the queue.
        // this.element.querySelector("button[type=submit]").addEventListener("click", function(e) {
        submitButton.addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            console.log(myDropzone.files);
            if (myDropzone.files && myDropzone.files.length > 0) {

                e.preventDefault();
                e.stopPropagation();
                myDropzone.processQueue();
                console.log("btn");
            } else {
                // $('#package_form').trigger('submit');
            }

        });

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sendingmultiple", function (files, xhr, formData) {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log("sendingmultiple");
            formData.append("_token", $('[name=_token]').val());
            var formValues = $('#package_form').serializeArray();
            console.log(formValues);
            $.each(formValues, function (key, value) {
                console.log(key, value);
                if (value.name != '_token') {
                    if (formData.has(value.name) == false) {
                        formData.append(value.name, value.value);
                    }
                }
            });
        });
        this.on("successmultiple", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            console.log("successmultiple");
        });
        this.on("errormultiple", function (files, errorMessage, response) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error
            var arr = [];
            $.each(errorMessage, function (key, value) {
                console.log(value);
                arr += value + "\n";
            });
            // show error message
            console.log(arr);
        });

        //максимальное количество файлов в контейнере
        this.on("sending", function (file, xhr, formData) {
            console.log("отправка файла");
            formData.append("_token", $('[name=_token]').val());
            var formValues = $('#package_form').serializeArray();
            console.log(formValues);
            $.each(formValues, function (key, value) {
                console.log(key, value);
                if (value.name != '_token' && value.name != 'images') {
                    formData.append(value.name, value.value);
                }
            });
        });
        this.on("success", function (file, responseText) {
            console.log('большой успех');
            this.removeFile(file);

            var form = document.getElementById("package_form");
            form.reset();

            var successAlert = document.querySelector('.alert-success');
            var successForm = document.querySelector('.dropzoneForm');
            if (successAlert) {
                successAlert.classList.add('show'); // Показываем блок
                setTimeout(function() {
                    successAlert.classList.remove('show'); // Скрываем блок через определенное время
                }, 3000); // Здесь 3000 - это время в миллисекундах (3 секунды)
            }
            if (successForm) {
                successForm.classList.add('hide'); // Показываем блок
                setTimeout(function() {
                    successForm.classList.remove('hide'); // Скрываем блок через определенное время
                }, 3000); // Здесь 3000 - это время в миллисекундах (3 секунды)
            }
        });
        this.on('addedfile', function (file) {
            console.log('файл добавлен');
            if (this.files.length > 3) {
                this.removeFile(this.files[3]);//удаляет последний 4-й файл по индексу
                alert("Максимальное количество 3 файла!");
            }
        });
    },
        // previewTemplate: '<a href="#"><img data-dz-thumbnail alt="Фото работы"></a>',

    });
});


var successAlert = document.querySelector('.alert-success');
var otherElement = document.querySelector('.successForm');

if (successAlert) {
    setTimeout(function() {
        successAlert.classList.add('d-none');
    }, 3000);
}
if (otherElement) {
    setTimeout(function() {
        otherElement.classList.remove('successForm');
    }, 3000);
}