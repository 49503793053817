//Кнопка наверх
window.addEventListener('DOMContentLoaded', function() {
    var scrollToTopBtn = document.querySelector('.scrollToTopBtn');

    // Показать кнопку прокрутки при достаточном прокручивании страницы
    window.addEventListener('scroll', function() {
        if (window.pageYOffset > 200) {
            scrollToTopBtn.style.display = 'block';
        } else {
            scrollToTopBtn.style.display = 'none';
        }
    });

    // Прокрутка страницы вверх при клике на кнопку
    scrollToTopBtn.addEventListener('click', function() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
});